<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}

::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}

::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}

::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}

::v-deep .van-field__body input {
	color: $font_color_val;
}

::v-deep .no_link .van-icon-arrow {
	color: #fff;
}

.btns {
	margin-top: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: center;

	.btn {
		width: 3rem;
		height: 0.9rem;
		font-size: 0.3rem;
		line-height: 0.9rem;
		text-align: center;
		border-radius: 0.08rem;
	}

	.btn_cancel {
		border: 1px solid $color_main;
		color: $color_main;
		background-color: #fff;
		margin-right: 0.4rem;
	}

	.btn_sure {
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
}
</style>

<template>
	<div>
		<top-nav @back="back">被保人信息</top-nav>
		<van-form ref="form" input-align="right" error-message-align="right">
			<van-cell-group :border="false">
				<van-field label="企业名称" v-model="userInfo.userName" :formatter="nameFormatter" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link :rules="rules.userName" placeholder="请填写企业名称" />
				<van-field label="统一社会信用代码" v-model="userInfo.socialCode" :formatter="idCardNoFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" :rules="rules.socialCode" placeholder="请填写统一社会信用代码" />
				<!-- <van-field label="出生日期" :value="userInfo.birthday"  label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.birthday"    placeholder="请选择出生日期" @click="birthdayPop = true" />
                <van-field label="性别"     :value="userInfo.sex"       label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.sex"    placeholder="请选择性别" @click="sexPop = true" />

                <van-field  label="手机号码"    v-model="userInfo.phone" :formatter="mobileFormatter"        class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link placeholder="请填写被保人手机号码" :rules="rules.phone" /> -->
				<!-- <van-field  label="电子邮箱"    v-model="userInfo.email"        class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link /> -->
				<!-- <div class="line"></div> -->
				<!-- <van-field  label="购买份数"    v-model="userInfo.buyNum"        class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link /> -->
				<div class="line"></div>
			</van-cell-group>
		</van-form>

		<div class="btns">
			<div class="btn btn_cancel" @click="cancel">取消</div>
			<div class="btn btn_sure" @click="sure">确定</div>
		</div>
	</div>
</template>

<script>
import { Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, Form } from 'vant';
import { cardType, sexs } from '@/config/fixedParams';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';

export default {
	name: 'grAddUser',
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[Form.name]: Form,
	},
	watch: {
		$route: {
			handler: function (route) {
				let params = route.params;
				if (params.hasOwnProperty('info')) {
					Object.assign(this.userInfo, params.info);
				}
			},
			immediate: true,
			deep: true,
		},
	},
	data() {
		return {
			fromRoute: '',
			// 投保人信息
			userInfo: {
				// userType: '',
				userName: '',
				socialCode: '',
				// cardType: '',
				// cardNum: '',
				// birthday: '',
				// sex: '',
				// phone: '',
				// email: '',
				// buyNum: '1',
				id: undefined,
			},
			rules: {
				userName: [
					{ required: true, message: '' },
					// { pattern: /^[a-zA-Z·]+$|^[\u4e00-\u9fa5·]+$/, message: '只能输入中文或英文以及·' },
					// { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' },
					{ validator: this.nameCheck, message: '·不能出现在首尾' },
				],
				socialCode: [
					{ required: true, message: '' },
					{ validator: this.cardNumCheck, message: '证件号码有误' },
				],
				// birthday: [{ required: true }],
				// sex: [{ required: true }],
				// phone: [
				//     { validator: this.phoneCheck, message: '请填写正确的手机号码' },
				// ],
			},
			userTypePop: false,
			cardTypePop: false,
			birthdayPop: false,
			userTypeList: [],
			cardTypeList: [],
			birthdayMinDate: new Date('1900/01/01'),
			birthday: new Date('1990/01/01'),
			sexPop: false,
			sexList: [],
		};
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.fromRoute = from.name;
		});
	},
	created() {
		this.initFixedParams();
	},
	methods: {
		// 回退
		back() {
			this.$router.push({ name: this.$store.state.createOrderRouteName });
		},

		// 投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// 投保人姓名格式化
		nameFormatter(v) {
			return v.toLocaleUpperCase().replace(/ /g, '');
		},

		// 身份证号码格式化
		idCardNoFormatter(v) {
			return String(v).toLocaleUpperCase().replace(/ /g, '');
		},

		// 证件类型校验方法
		cardNumCheck(val) {
			return Mtils.validation.isCreditCode(val);
		},

		// // 手机号码格式化
		// mobileFormatter(v) {
		//     return String(v).replace(/ /g, "")
		// },

		// 初始化固定参数
		initFixedParams() {
			this.cardTypeList = cardType;
			if (!this.userInfo.cardType) {
				this.userInfo.cardType = cardType[0].label;
			}
			this.sexList = sexs;
		},

		// userTypeCheck(val) {
		//     this.userInfo.userType = val;
		//     this.userTypePop = false;
		// },

		// cardTypeCheck(val) {
		//     this.userInfo.cardType = val.label;
		//     this.cardTypePop = false;
		// },

		// birthdayCheck(val) {
		//     this.userInfo.birthday = this.$base.dateFormater(val);
		//     this.birthdayPop = false;
		// },

		// sexCheck(val) {
		//     this.userInfo.sex = val.label;
		//     this.sexPop = false;
		// },

		// 证件号码输入
		cardNumChange(val) {
			if (val && this.userInfo.cardType === '身份证') {
				// 处理大小写问题
				this.userInfo.cardNum = String(val).toLocaleUpperCase();
			}
			if (this.userInfo.cardType === '身份证' && Mtils.validation.isIdCard(this.userInfo.cardNum)) {
				// 计算
				let info = this.$base.getInfoFromIdNumber(val);
				this.userInfo.birthday = info.birthday;
				this.userInfo.sex = info.sex;
			}
		},

		cancel() {
			this.back();
		},

		phoneCheck(val) {
			if (!val) {
				return true;
			}
			return regular.phone.test(val);
		},

		// // 证件类型校验方法
		// cardNumCheck(val) {
		//     if (this.userInfo.cardType === '身份证') {
		//         return Mtils.validation.isIdCard(val)
		//     }
		//     if (this.userInfo.cardType === '护照') {
		//         return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val)
		//     }
		//     if (this.userInfo.cardType === '军官证') {
		//         return regular.army.test(val)
		//     }
		//     if (this.userInfo.cardType === '港澳回乡证') {
		//         return regular.hkCard.test(val)
		//     }
		//     if (this.userInfo.cardType === '台胞证') {
		//         return regular.twCard.test(val)
		//     }
		//     return true
		// },

		sure() {
			this.$refs.form.validate().then(() => {
				this.$router.push({
					name: this.$store.state.createOrderRouteName,
					params: {
						userInfo: this.userInfo,
					},
				});
			});
		},
	},
};
</script>
